<template>
  <div class="Building">
    <!-- 横幅 -->
    <div class="banner">
      <img src="../../../assets/SanWeiGuZhen/SanWeiVideo/banner.png" alt="">
    </div>
    <!-- 内容 -->
    <div class="video-content">
      <!-- 导航标题 -->
      <div class="DaoHangTitle">
        <span @click="SanWeiGZ()">三维古镇</span>
        <div><img src="../../../assets/VRVisit/VideoLog/Rights.png" alt=""></div>
        <span>单体建筑</span>
      </div>
      <!-- 大视频 -->
      <div class="bigVideo">
        <div class="video-content-left">
          <img class="bigv" :src="url[0]" alt="">
          <div class="zan-live-share">
            <!-- 点赞 -->
            <div class="zan" @click="liang()">
              <img class="zanImage" :src="zansrc" alt="">
              <span class="zan-text">点赞</span>
            </div>
            <!-- 收藏 -->
            <div class="live">
              <img src="../../../assets/SanWeiGuZhen/SanWeiVideo/Xing.png" alt="">
              <span class="zan-text">收藏</span>
            </div>
            <!-- 分享 -->
            <div class="share">
              <img src="../../../assets/SanWeiGuZhen/SanWeiVideo/ZhuanFa.png" alt="">
              <span class="zan-text">转发</span>
            </div>
          </div>
        </div>
        <!-- 视频内容信息 -->
        <div class="video-content-information">
          <div class="video-title">
            {{ data.subtitle }}
          </div>
          <div class="video-time font">
            2021年12月23发布
          </div>
          <div class="zuopinlaiyuan font">
            作品来源：
          </div>
          <!--  -->
          <div class="introduce font">
            内容简介：
          </div>
        </div>
      </div>
    </div>
    <!-- 其他视频 -->
    <div class="otherVideo">
      <div class="otherVideo-item" v-for="item in imgsLength" :key="item">
        <img class="smallv" @click="show(item - 1)" :src="url[item]" alt="">
        <div class="otherVideo-name">
          <span> &nbsp; {{ title[item] }}</span>
        </div>
      </div>
    </div>

    <Bottom_Bar></Bottom_Bar>
    <Navigation_Buttons></Navigation_Buttons>
  </div>
</template>

<script>
import Navigation_Buttons from '../../Public/Navigation_Buttons'
import Bottom_Bar from '../../Public/Bottom_Bar'
import { log } from 'console';
let flag = false
export default {
  components: { Navigation_Buttons, Bottom_Bar },
  data() {
    return {
      url: [],
      title: [],
      createTime: [],
      imgsLength: '',
      data: [],
      zansrc: require('../../../assets/SanWeiGuZhen/SanWeiVideo/Zan.png'),
      bluezan: require('../../../assets/SanWeiGuZhen/blueZan.png'),
    }
  },
  created() {
    const axios = require('axios').default;
    axios(
      {
        method: 'GET',
        url: 'http://8.134.93.240:8088/virturaltravel/app/contentPublish/getContentPublishDetail/157'
      }
    )
      .then(res => {
        let url = []
        let title = []
        let createTime = []
        var dataSource = res.data.data.contentPublish;
        if (res.data.code == 0) {
          this.data = dataSource;
          this.imgs = dataSource.imgs;
          this.imgsLength = this.imgs.length - 1
          for (let i = 0; i < this.imgs.length; i++) {
            this.url.push(this.imgs[i].resourceUrl);
            this.title.push(this.imgs[i].title);
            this.createTime.push(this.imgs[i].createTime);
          }
        }
      })
  },
  methods: {
    SanWeiGZ() {
      this.$router.push('/ancientTown')
    },
    show(index) {
      let bigv = document.querySelector('.bigv');
      let smv = document.querySelectorAll('.smallv')
      bigv.src = smv[index].src
    },
    liang() {
      let zan = document.querySelector('.zanImage');
      if (flag == false) {
        zan.src = this.bluezan
        flag = true
      } else {
        zan.src = this.zansrc
        flag = false
      }
    },
  }
}
</script>

<style lang="less" scoped>
@import url(./Building.less);
</style>